.App {
  text-align: center;
  font-size: calc(20px + 2vmin);
  color: #282c34;
}

.App-header {
  font-size: calc(4px + 2vmin);
  background-color: white;
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-link {
  color: #61dafb;
}

.source {
  font-size: 15px;
}

.search {
  width: 20%;
  position: relative;
  border: 3px solid #cccccc;
  padding: 5px;
  height: 20px;
  border-radius: 5px;
  outline: none;
  color: #282c34;
}
