pre {
  background-color: black;
  margin: 1.4em;
  padding: 1.2em;
  border-radius: 0.25em;
  box-shadow: 0.1em 0.1em 0.5em rgba(0, 0, 0, 0.45);
  line-height: 0;
  counter-reset: line;
  color: white;
}

.Popover-body {
  display: inline-flex;
  flex-direction: column;
  padding: 1rem 1.8rem;
  background: hsl(0, 0%, 27%);
  color: white;
  border-radius: 0.25rem;
}

.Popover-tipShape {
  fill: hsl(0, 0%, 27%);
}
